<template>
  <b-field
    v-if="schema.allowed"
    :label="schema.label"
    :message="errors"
    :type="{ 'is-danger': errors ? errors.length : false }"
  >
    <b-datepicker
      v-model="content"
      :min-date="minDate"
      :max-date="maxDate"
      :date-parser="dateParser"
      editable
      locale="en-US"
      placeholder="Click to select..."
      icon="calendar-today"
      :mobile-modal="false"
      :mobile-native="false"
      trap-focus
      :use-html5-validation="false"
      @input="$emit('input', content)"
    ></b-datepicker>
  </b-field>
</template>

<script>
export default {
  props: {
    value: Date,
    schema: Object,
    errors: String,
  },
  data() {
    return {
      content: this.value,
    };
  },
  computed:{
    // Min date: 100 years ago (adjust as needed)
    minDate() {
      const date = new Date();
      date.setFullYear(date.getFullYear() - 100);
      return date;
    },
    // Max date: current date
    maxDate() {
      return new Date();
    },
  },
  methods: {
    dateParser(s) {
      // Change 01071981 -> 01/07/1981. Allows fast input without slashes.
      if (s.length === 8) {
        s = `${s.substr(0, 2)}/${s.substr(2, 2)}/${s.substr(4, 4)}`;
      }
      const parsedDate = new Date(s);
      const today = new Date();
      if (parsedDate > today || parsedDate < this.minDate) {
        return null;
      }
      return new Date(s);
    },
  },
};
</script>
