<template>
  <div>
    <breadcrumb-list>
      <breadcrumb-link view="Home" label="Home" />
      <breadcrumb-link view="InquiryList" label="Inquiries" />
      <breadcrumb-link
        v-if="inquiry"
        view="InquiryDetail"
        :label="pageTitle"
        :params="{ id: inquiry.id }"
        :active="true"
      />
    </breadcrumb-list>

    <div v-if="inquiry">
      <inquiry-expired v-if="!inquiry.coverage" :inquiry-id="inquiry.id"></inquiry-expired>

      <inquiry-detail-plan
        v-else
        :raw-plan="inquiry?.coverage?.plan"
        :createdAt="inquiry.created_at"
        :asOfDate="inquiry.as_of_date"
        :inquiry-id="inquiry.id"
        :payer="inquiry.payer"
        :response-payer="inquiry.coverage.payer_name"
        :patient="inquiry.coverage.patient"
        :subscriber="inquiry.coverage.subscriber"
        :provider="inquiry?.provider.name"
        :payer-name-override="inquiry.payer_name_override"
        :input-does-not-match-messages="inputDoesNotMatchMessages"
      ></inquiry-detail-plan>
    </div>
  </div>
</template>

<script>
import InquiryDetailPlan from '../components/InquiryDetailPlan.vue';
import InquiryExpired from '../components/InquiryExpired.vue';
import request from '../request';

export default {
  name: 'InquiryDetail',
  components: {
    InquiryDetailPlan,
    InquiryExpired,
  },
  computed: {
    pageTitle() {
      if (this.inquiry?.coverage?.patient) {
        let firstName = this.inquiry.coverage.patient.first_name;
        let lastName = this.inquiry.coverage.patient.last_name;
        return `${firstName} ${lastName}`;
      }
      return this.inquiry.id;
    },
  },
  mounted() {
    if (this.inquiry && this.inquiry.coverage && this.inquiry.submission) {
      // check memberId
      if (
        this.inquiry.submission.memberId &&
        this.inquiry.submission.memberId !== '' &&
        this.inquiry.submission.memberId.toUpperCase().trim() !==
          this.inquiry.coverage.subscriber.member_id.toUpperCase().trim()
      ) {
        const inputMemberId = this.inquiry.submission.memberId;
        const remoteMemberId = this.inquiry.coverage.subscriber.member_id;
        const msg = `MemberID '${inputMemberId}' was entered, but the payer returned '${remoteMemberId}'`;
        this.inputDoesNotMatchMessages.push(msg);
      }

      // check birthDate
      const patientBirthDate = this.inquiry.submission.patientBirthDate ?? '';
      if (patientBirthDate !== '') {
        const inputBirthDate = patientBirthDate.split('T')[0];
        const remoteBirthDate = this.inquiry.coverage.patient.dob.split('T')[0];

        if (inputBirthDate !== remoteBirthDate) {
          const msg = `BirthDate '${inputBirthDate}' was entered, but the payer returned '${remoteBirthDate}'`;
          this.inputDoesNotMatchMessages.push(msg);
        }
      }

      // check first and last name
      if (
        this.inquiry.submission.patientFirstName !== '' &&
        this.inquiry.submission.patientLastName !== ''
      ) {
        let inputName = `${this.inquiry?.submission?.patientFirstName?.trim() ?? ''} ${
          this.inquiry?.submission?.patientLastName?.trim() ?? ''
        }`;
        inputName = inputName.toUpperCase();

        let remoteName = `${this.inquiry?.coverage?.patient?.first_name?.trim() ?? ''} ${
          this.inquiry?.coverage?.patient?.last_name?.trim() ?? ''
        }`;
        remoteName = remoteName.toUpperCase();

        if (inputName.trim() != '' && inputName !== remoteName) {
          const msg = `Name '${inputName}' was entered, but the payer returned '${remoteName}'`;
          this.inputDoesNotMatchMessages.push(msg);
        }
      }
    }
  },
  data() {
    return {
      inquiry: null,
      inputDoesNotMatchMessages: [],
    };
  },
  beforeRouteEnter(to, from, next) {
    request
      .get(`/inquiries/${to.params.id}`)
      .then((resp) => {
        let status = resp.data.inquiry.status;
        if (status === 'complete' || status === 'expired') {
          next((vm) => (vm.inquiry = resp.data.inquiry));
        } else {
          next({ name: 'NotFound' });
        }
      })
      .catch(() => {
        next({ name: 'NotFound' });
      });
  },
};
</script>
