<template>
  <section>
    <b-field>
      <b-button
        icon-left="delete"
        type="is-danger"
        v-if="checkedRows.length && !isForAdmin && isManager"
        @click="handleBulkRemove"
      >
        Remove selected inquiries
      </b-button>
    </b-field>
    <b-field>
      <div class="field-body">
        <b-input class="is-expanded" :loading="loading"  v-model="search" placeholder="Search by First or last name, DOB (MM/DD/YYYY), Member ID, or Payer" @input="emitSearchTerm" />
        <b-dropdown
          class="filter-dropdown"
          position="is-bottom-left"
          v-model="searchableFields"
          multiple
          aria-role="list"
        >
          <template #trigger>
            <b-button
              icon-right="filter"
              type="is-primary"
            >
              Search Fields ({{ searchableFields.length }})
            </b-button>
          </template>

          <b-dropdown-item
            v-for="field in allFields"
            :key="field.value"
            :value="field.value"
            aria-role="listitem"
          >
            <div @click="toggleCheckbox(field.value)">
              <b-checkbox
                v-model="searchableFields"
                :native-value="field.value"
                class="checkbox-left"
              >
                {{ field.label }}
              </b-checkbox>
            </div>

          </b-dropdown-item>
        </b-dropdown>
      </div>
    </b-field>

    <b-table
      :data="options"
      sort-icon="chevron-up"
      sort-icon-size="is-small"
      :default-sort="['date', 'desc']"
      striped
      hoverable
      :checkable="!isForAdmin && isManager"
      :checked-rows.sync="checkedRows"
      :debounce-search="1000"
      :detailed="isForAdmin"
      detail-key="id"
      detail-transition="fade"
      :show-detail-icon="showDetailIcon"
    >
      <b-table-column field="patient" label="Patient" sortable v-slot="props">
        <router-link
          v-if="props.row.status === 'complete' || props.row.status === 'expired'"
          :to="{ name: 'InquiryDetail', params: { id: props.row.id } }"
        >
          {{ props.row.patient }}
        </router-link>
        <span v-else>
          {{ props.row.patient }}
        </span>
      </b-table-column>

      <b-table-column field="user" label="User" sortable v-slot="props">
        {{ props.row.user }}
      </b-table-column>

      <b-table-column field="provider" label="Provider" sortable v-slot="props" v-if="isForAdmin">
        {{ props.row.provider }}
      </b-table-column>

      <b-table-column field="payer" label="Payer" sortable v-slot="props">
        {{ props.row.payer }}
      </b-table-column>

      <b-table-column field="created_at" label="Date" sortable centered v-slot="props">
        {{ props.row.date }}
      </b-table-column>

      <b-table-column
        v-if="isForAdmin"
        field="is_hidden"
        label="Visibility"
        sortable
        numeric
        v-slot="props"
      >
        <b-icon v-if="props.row.is_hidden" icon="eye-off-outline"></b-icon>
      </b-table-column>

      <b-table-column field="status" label="Status" sortable numeric v-slot="props">
        <span
          :class="{
            tag: true,
            'is-success': props.row.plan_status == 'active',
            'is-danger': props.row.plan_status == 'inactive',
            'is-light': props.row.plan_status == 'draft',
            'is-warning': props.row.plan_status == 'unavailable',
          }"
        >
          <span>{{ props.row.plan_status }}</span>
        </span>
      </b-table-column>

      <b-table-column v-if="isForAdmin" field="had_reimbursement" label="EIV" sortable v-slot="props">
        <b-icon v-if="props.row.had_reimbursement"
          type="is-success"
          icon="check-circle"
          size="is-small"
        />
      </b-table-column>

      <b-table-column label="Actions" numeric v-slot="props" v-if="!isForAdmin">
        <router-link :to="{ name: 'InquiryDraft', params: { id: props.row.id } }" v-if="props.row.status === 'draft'" >
          <b-icon icon="book-edit"></b-icon>
        </router-link>
        <router-link :to="{ name: 'InquiryCopy', params: { id: props.row.id } }" v-else>
          <b-icon icon="reload"></b-icon>
        </router-link>
      </b-table-column>
      <template #detail="props">
        <p>
          {{ props.row.submission }}
        </p>
      </template>
    </b-table>

    <div class="pagination-container">
      <b-pagination
        v-model="currentPageLocal"
        :total="totalItems"
        :page-size="pageSize"
        @change="emitPageChange"
      ></b-pagination>
    </div>
  </section>
</template>

<script>
import request from '../request';
import { mapGetters } from 'vuex';
export default {
  name: 'InquiryTable',
  props: {
    options: Array,
    isForAdmin: {
      type: Boolean,
      default: false,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      required:true
    },
    totalItems:{
      type:Number,
      required:true
    },
    loading:{
      type:Boolean,
      default:true
    }
  },
  data() {
    return {
      search: '',
      searchableFields: ['patient_first_name','patient_last_name','member_id','patient_birth_date','payer_name'],
      allFields: [
        { value: 'patient_first_name', label: 'First Name' },
        { value: 'patient_last_name', label: 'Last Name' },
        { value: 'patient_birth_date', label: 'Birth Date' },
        { value: 'member_id', label: 'Member ID' },
        { value: 'payer_name', label: 'Payer Name' }
      ],
      checkedRows: [],
      showDetailIcon: true,
      currentPageLocal:this.currentPage,
    };
  },
  computed: {
    ...mapGetters(['isManager']),
  },
  methods: {
    handleBulkRemove() {
      let message = `Are you sure you want to remove ${this.checkedRows.length} inquiries?`;
      message += `<br/><br/>NOTE: This will not delete the related data, but these inquiries will no longer be visible to you or your organization's users.`;

      this.$buefy.dialog.confirm({
        title: 'Remove inquiries',
        message: message,
        confirmText: `Remove ${this.checkedRows.length} inquiries`,
        type: 'is-danger',
        onConfirm: () => {
          let ids = this.checkedRows.map((r) => r.id);
          request
            .put(`/inquiries`, { inquiry_ids: ids, is_hidden: true })
            .then(() => {
              this.$buefy.toast.open({
                message: `Successfully removed ${this.checkedRows.length} inquiries`,
                type: 'is-success',
                duration: 10000,
              });
              this.checkedRows = [];
            })
            .finally(() => {
              // emit regardless just to ensure we are up to date
              this.$emit('bulkremove');
            });
        },
      });
    },
    emitPageChange(newPage) {
      this.$emit('page-change', newPage);  // Emit event to parent component when page changes
    },
    emitSearchTerm() {
      this.$emit('search-changed', {
        search: this.search,
        fields: this.searchableFields,
      });
    },
    toggleCheckbox(value) {
      const index = this.searchableFields.indexOf(value);
      if (index === -1) {
        this.searchableFields.push(value); // Add the field to the selected list
      } else {
        this.searchableFields.splice(index, 1); // Remove the field from the selected list
      }
    }
  },
};
</script>

<style scoped>
.pagination-container {
  display: flex;
  justify-content: flex-end;
}

.field-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.is-expanded {
  flex-grow: 1;
}
.filter-dropdown {
  margin-left: 10px;
}
/* Prevent the background color on selection */
.filter-dropdown .dropdown-item {
  color: black !important;
}

/*Remove background color on hover or active state */
.filter-dropdown .dropdown-item.is-active,
.filter-dropdown .dropdown-item.is-hovered {
  background-color: transparent !important;
}
.checkbox-left {
  display: inline-block;
  padding-left: 10px;
  margin-right: 10px;
}
.filter-dropdown .dropdown-item .b-checkbox {
  display: inline-flex;
  align-items: center;
}
</style>