<template>
  <div>
    <breadcrumb-list>
      <breadcrumb-link view="Home" label="Home" />
      <breadcrumb-link view="InquiryList" label="Inquiries" :active="true" />
    </breadcrumb-list>
    <section>
      <div class="container is-max-hd">
        <div class="box">
          <div class="level">
            <div class="level-left">
              <div class="level-item">
                <h1 class="title">Inquiry List</h1>
              </div>
            </div>
            <div class="level-right">
              <div class="level-item">
                <b-field>
                  <b-select
                    v-model="selectedUserID"
                    placeholder="Show Inquiries for User"
                    :loading="loading"
                    @input="handleSelect()"
                  >
                    <option :value="0">All Users</option>
                    <option v-for="opt in userOptions" :value="opt.id" :key="opt.id">
                      {{ opt.first_name }} {{ opt.last_name }}
                    </option>
                  </b-select>
                </b-field>
              </div>
              <div class="level-item">
                <b-field>
                  <b-select
                    v-model="selectedProviderID"
                    placeholder="Show Inquiries for Provider"
                    :loading="loading"
                    @input="handleSelect()"
                  >
                    <option :value="0">All Providers</option>
                    <option v-for="opt in providerOptions" :value="opt.id" :key="opt.id">
                      {{ opt.name }}
                    </option>
                  </b-select>
                </b-field>
              </div>
              <div class="level-item">
                <b-button type="is-primary" tag="router-link" :to="{ name: 'InquiryCreate' }">
                  Create New Inquiry
                </b-button>
              </div>
            </div>
          </div>
          <hr />

          <div>
            <InquiryTable
              :options="inqOptions"
              :current-page="currentPage"
              :page-size="pageSize"
              :total-items="totalItems"
              :loading="loading"
              @page-change="handlePageChange"
              @bulkremove="fetchInquiries"
              @search-changed="handleSearchChange"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios';

import InquiryTable from '../components/InquiryTable.vue';
import request from '../request';
import { mapGetters } from 'vuex';

export default {
  name: 'InquiryList',
  components: {
    InquiryTable,
  },
  data() {
    return {
      currentPage: 1,    // Current page for pagination
      pageSize: 15,      // Number of items per page
      totalItems: 0,      // Number of items per page
      selectedUserID: 0,
      selectedProviderID: 0,
      inqOptions: [],
      userOptions: [],
      providerOptions: [],
      loading: true,
      selectedStatus:['pending','complete','max-retry','unknown','expired','cancel','draft'],
      search:null,
      searchableFields:[],
    };
  },
  created() {
    axios
      .all([
        request('/users').then((resp) => {
          // Sort users alphabetically by first name.
          resp.data.users.sort((a, b) => a.first_name.localeCompare(b.first_name));
          this.userOptions = resp.data.users;
        }),
        this.fetchInquiries(),
      ])
      .finally(() => (this.loading = false));

    axios
      .all([
        request('/providers').then((resp) => {
          // Sort providers alphabetically by name.
          resp.data.providers.sort((a, b) => a.name.localeCompare(b.name));
          this.providerOptions = resp.data.providers;
        }),
        this.fetchInquiries(),
      ])
      .finally(() => (this.loading = false));
  },
  methods: {
    handleSelect() {
      this.loading = true;
      this.fetchInquiries().finally(() => (this.loading = false));
    },
    fetchInquiries() {
      this.loading = true;
      let args = {
        page: this.currentPage,
        limit: this.pageSize,
      };
      if (this.selectedUserID != 0) {
        args['user_id'] = this.selectedUserID;
      }
      if (this.selectedProviderID != 0) {
        args['provider_id'] = this.selectedProviderID;
      }
      if (this.selectedStatus && this.selectedStatus.length > 0) {
        args['status'] = this.selectedStatus.join(',');
      }
      // Include the search term if entered
      if (this.search) {
        args['search_term'] = this.search;
      }
      if (this.searchableFields && this.searchableFields.length > 0) {
        args['search_fields'] = this.searchableFields.join(',');
      }

      let params = new URLSearchParams(args).toString();
      return request(`/inquiries?${params}`).then(
        (resp) => {
          this.inqOptions = resp.data.inquiries
          this.totalItems=Number(resp.headers['x-total-count'])
          this.loading = false;
        }
      ).catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$buefy.toast.open({
              message: 'Session expired. Please log in again.',
              type: 'is-danger',
              duration: 5000
            });
            this.$router.push({ name: 'login' });
          } else {
            this.$buefy.toast.open({
              message: 'An error occurred. Please try again later.',
              type: 'is-danger',
              duration: 5000
            });
          }
        });
    },
    handlePageChange(newPage) {
      this.currentPage = newPage;
      this.fetchInquiries();
    },
     handleSearchChange({ search, fields }) {
        this.search = search;
       this.searchableFields = fields;
       this.fetchInquiries();
      },

    },
  computed: {
    ...mapGetters(['isManager', 'user']),
    selectedUser() {
      return this.userOptions.find((opt) => opt.id === this.selectedUserID);
    },
  },
};
</script>
