<template>
  <section class="hero is-primary">
    <div class="hero-body">
      <div class="container is-max-hd">
        <div class="columns">
          <div class="column">
            <p class="title is-3">{{ patient.first_name }} {{ patient.last_name }}</p>
            <p class="subtitle">
              <template v-if="showSubscriberName">
                <strong>Subscriber Name:</strong>
                <span>{{ subscriber.first_name }} {{ subscriber.last_name }}</span>
                <br />
              </template>

              <strong>{{ personType }}</strong>
              <span v-if="patient.gender">&nbsp;- {{ patient.gender }}</span>
              <span v-if="patient.dob">&nbsp;- {{ patient.dob | formatDate }}</span>
              <br />
              <span v-if="subscriber.member_id">
                <strong>MemberID</strong>
                :
                {{ subscriber.member_id }}
              </span>
              <br />
              <span>
                <strong>Facility</strong>
                :
                {{ provider }}
              </span>
              <br />
              <span>
                <strong>Created</strong>
                :
                {{ createdAt | formatDate }}
              </span>
              <br />
              <span>
                <strong>As of</strong>
                :
                {{ asOfDate | formatDate }}
              </span>
            </p>
          </div>

          <div class="column has-text-left">
            <p class="subtitle" v-if="showPatientAddress">
              <strong>Patient Address:</strong>
              {{ patient.address.street }}
              <br />
              {{ patient.address.city }}, {{ patient.address.state }},
              {{ patient.address.zip }}
            </p>

            <p class="subtitle" v-if="showSubscriberAddress">
              <strong>Subscriber Address:</strong>
              {{ subscriber.address.street }}
              <br />
              {{ subscriber.address.city }}, {{ subscriber.address.state }},
              {{ subscriber.address.zip }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'InquiryDetailHeader',
  props: {
    createdAt: String,
    asOfDate: String,
    provider: String,
    patient: Object,
    subscriber: Object,
    isPdf: { type: Boolean, default: false },
  },
  computed: {
    showSubscriberName() {
      return this.patient.relationship !== 'Self';
    },
    showPatientAddress() {
      return Object.hasOwn(this.patient, 'address');
    },
    showSubscriberAddress() {
      return Object.hasOwn(this.subscriber, 'address');
    },
    personType() {
      if (this.patient.relationship === 'Self') {
        return 'Subscriber';
      }
      return this.patient.relationship;
    },
  },
};
</script>
